import PropTypes from 'prop-types';
import React from 'react';
import { TooltipBox } from '@shoreag/base/Tooltip';

const TagTooltipBox = ({
  tooltipProps: { sx: tooltipSx, ...tooltipProps },
}) => (
  <TooltipBox
    id="tags"
    tooltipProps={{
      sx: {
        '.tag': {
          bg: 'accent',
          borderRadius: 1,
          fontSize: 2,
          m: 2,
          px: 3,
          py: 1,
        },
        '.tag-wrapper': {
          '&::-webkit-scrollbar': { width: '4px' },
          '&::-webkit-scrollbar-thumb': {
            background: 'white',
            borderRadius: 1,
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          },
          display: 'flex',
          flexWrap: 'wrap',
          maxHeight: 'inherit',
          overflow: 'auto',
          p: 2,
        },
        p: 0,
        pointerEvents: 'auto',
        ...(tooltipSx || {}),
      },
      ...tooltipProps,
    }}
  />
);

TagTooltipBox.propTypes = {
  tooltipProps: PropTypes.shape({ sx: PropTypes.shape({}) }),
};

TagTooltipBox.defaultProps = {
  tooltipProps: {},
};

export default TagTooltipBox;
