import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import formatDuration from '@shoreag/helpers/format-duration';
import { ParentSize } from '@vx/responsive';
import { bisector } from 'd3-array';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { ThemeContext } from 'styled-components';
import executionAnalyticsQuery from '../../graphql/queries/execution-analytics.gql';
import { getSuffixData } from '../../utilities/get-suffix-data';
import AreaGraph from '../AreaGraph';

const getData = (field, interval, searchBody) => {
  const { data, loading } = useQuery(executionAnalyticsQuery, {
    variables: {
      field,
      interval,
      searchBody,
    },
  });
  const executionAnalytics = get(data, 'executionAnalytics', {
    data: [],
    total: 0,
  });

  return {
    data: executionAnalytics.data,
    loading,
    total: executionAnalytics.total,
  };
};

const PipelineExecutionDashboardAnalytics = ({ showAnalytics, variables }) => {
  const { searchBody } = variables;
  const theme = useContext(ThemeContext);
  const colors = theme.colors.visualizations;

  const analytics = {
    averageExecutionDuration: getData('startDate', 'day', searchBody),
  };

  return (
    <Box
      sx={{
        height: 'auto',
        maxHeight: showAnalytics ? '300px' : 0,
        overflow: showAnalytics ? 'visible' : 'hidden',
        transition: 'max-height 0.5s ease-in-out',
      }}
    >
      <ParentSize>
        {({ width }) => {
          const cardWidth = width - 20;
          return (
            <Box
              sx={{
                borderRadius: 2,
                display: 'flex',
                height: 250,
                mb: 5,
              }}
            >
              <Card
                sx={{
                  borderRadius: 2,
                  boxShadow: 0,
                  mb: 3,
                  p: 3,
                }}
                width={`calc(${cardWidth} + 10px)`}
              >
                <Box
                  alignItems="center"
                  height={200}
                  textAlign="center"
                  width={cardWidth}
                >
                  <Box as="h5">Average Execution Duration</Box>
                  <AreaGraph
                    areaFillColor={colors[1]}
                    bisector={bisector((d) => d.label).left}
                    dotColor={colors[3]}
                    formatTooltip={(date, duration) => (
                      <Box textAlign="left">
                        <Box as="h5">{date}</Box>
                        <Box
                          as="h5"
                          sx={{
                            color: colors[0],
                          }}
                        >
                          {parseInt(duration, 10) >= 1000
                            ? formatDuration(parseInt(duration, 10))
                            : `${parseInt(duration, 10)} milliseconds`}
                        </Box>
                      </Box>
                    )}
                    lineColor={colors[0]}
                    loading={analytics.averageExecutionDuration.loading}
                    mainData={analytics.averageExecutionDuration.data}
                    margin={{ bottom: 20, left: 10, right: 10, top: 20 }}
                    suffixData={getSuffixData(
                      analytics.averageExecutionDuration.data,
                      3
                    )}
                    xAccessor={(d) => d.label}
                    xLabel="DATE"
                    yAccessor={(d) => d.value}
                    yLabel="DURATION"
                  />
                </Box>
              </Card>
            </Box>
          );
        }}
      </ParentSize>
    </Box>
  );
};

PipelineExecutionDashboardAnalytics.propTypes = {
  showAnalytics: PropTypes.bool.isRequired,
  variables: PropTypes.shape({
    searchBody: PropTypes.string,
  }).isRequired,
};

export default PipelineExecutionDashboardAnalytics;
