import generateSearchLink from '../../../utilities/generate-search-link';
import { PAGINATION_PARAMS } from '../../../utilities/constants';

const generateDashboardOnNextClickSearchLink = ({
  filters,
  pageSize,
  pathname,
}) =>
  generateSearchLink({
    filters: {
      ...filters,
      [PAGINATION_PARAMS.OFFSET]:
        (filters[PAGINATION_PARAMS.OFFSET] || 0) + pageSize,
    },
    pathname,
  });

export default generateDashboardOnNextClickSearchLink;
