import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { EXECUTION_STATUSES } from '../../utilities/constants';

const statusColorMap = {
  [EXECUTION_STATUSES.FAILED]: 'error',
  [EXECUTION_STATUSES.NOT_STARTED]: 'grays.4',
  [EXECUTION_STATUSES.RUNNING]: 'warning',
  [EXECUTION_STATUSES.SUCCESS]: 'success',
};

const PipelineExecutionStatusDot = ({ status, sx, ...rest }) => (
  <Box
    sx={{
      bg: statusColorMap[status],
      borderRadius: '50%',
      height: '10px',
      width: '10px',
      ...sx,
    }}
    {...rest}
  />
);

PipelineExecutionStatusDot.propTypes = {
  status: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

PipelineExecutionStatusDot.defaultProps = {
  sx: {},
};

export default PipelineExecutionStatusDot;
