export const getSuffixData = (mainData, n) => {
  const l = mainData.length;
  if (l > 1) {
    const n1 = parseInt(mainData[l - 1].label, 10);
    const n2 = parseInt(mainData[l - 2].label, 10);
    const d = n1 - n2;
    const res = [];
    for (let i = 1; i <= n; i += 1) {
      res.push({
        label: (n1 + d * i).toString(),
        value: 0,
      });
    }
    return res;
  }
  return [];
};
