import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import PropTypes from 'prop-types';
import React from 'react';
import ResponsiveEllipsis from '../ResponsiveEllipsis';

const LinkWithVersion = ({ ellipsis, link, name, version }) => (
  <Box alignItems="center" display="flex">
    {version !== null && (
      <Box
        sx={{
          bg: 'grays.4',
          borderRadius: 0,
          color: 'white',
          display: 'inline-block',
          fontSize: 1,
          fontWeight: 'bold',
          letterSpacing: 1,
          mr: 2,
          textAlign: 'center',
          textTransform: 'uppercase',
          width: '2rem',
        }}
      >
        v{version}
      </Box>
    )}
    <Button link={link} simple sx={{ width: '100%' }} variant="buttons.link">
      {ellipsis ? <ResponsiveEllipsis text={name} /> : name}
    </Button>
  </Box>
);

LinkWithVersion.propTypes = {
  ellipsis: PropTypes.bool,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  version: PropTypes.number,
};

LinkWithVersion.defaultProps = {
  ellipsis: true,
  version: null,
};

export default LinkWithVersion;
