import fromPairs from 'lodash/fromPairs';
import generateRedirectPath from '@shoreag/helpers/generate-redirect-path';
import moment from 'moment';
import { SEARCH_PARAMS } from './constants';

const generateSearchLink = ({ filters, pathname }) =>
  generateRedirectPath({
    pathname,
    queryParams: fromPairs(
      Object.entries(filters).map(([key, value]) =>
        key === SEARCH_PARAMS.CREATED && Array.isArray(value)
          ? [key, value.map((i) => moment(i).valueOf())]
          : [key, value]
      )
    ),
  });

export default generateSearchLink;
