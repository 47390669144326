import Box from '@shoreag/base/Box';
import Card from '@shoreag/base/Card';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Table from '@shoreag/base/Table';
import formatDateTimeConcise from '@shoreag/helpers/format-date-time-concise';
import get from 'lodash/get';
import { Query } from 'react-apollo';
import DashboardFilterForm from '../DashboardFilterForm';
import DashboardPaginator from '../DashboardPaginator';
import LinkWithVersion from '../LinkWithVersion';
import Route from '../Route';
import TagTooltipBox from '../TagTooltip/TagTooltipBox';
import allExecutionQuery from '../../graphql/queries/all-execution.gql';
import setDashboardURL from '../../utilities/set-dashboard-url';
import ButtonGroups from '../ButtonGroups';
import ToolbarButton from '../ToolbarButton';
import dashboardConstants from '../../utilities/dashboard-constants';
import parseUuid from '../../utilities/parse-uuid';
import PipelineExecutionStatusDot from '../PipelineExecutionStatusDot';
import PipelineExecutionResultPills from '../PipelineExecutionResultPills';
import PipelineExecutionDashboardAnalytics from '../PipelineExecutionDashboardAnalytics';

const ExecutionDashboardPage = ({ location, navigate }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const {
    filters,
    sortCriteria,
    pageCriteria,
  } = dashboardConstants.getCriterias({
    search: location.search,
    type: 'executions',
  });
  const query = dashboardConstants.getQuery({
    filters,
    page: pageCriteria,
    sort: sortCriteria,
  });
  const analyticsSearchBody = dashboardConstants.getQuery({
    filters,
  });
  return (
    <Route
      header={{
        icon: 'play',
        rightContainer: (
          <ButtonGroups>
            <ToolbarButton
              active={showFilters || filters.filterApplied}
              icon={filters.filterApplied ? 'filter' : 'menu'}
              label="Filters"
              onClick={() => setShowFilters(!showFilters)}
            />
            <ToolbarButton
              active={showAnalytics}
              icon="bar-chart"
              label="Analytics"
              onClick={() => setShowAnalytics(!showAnalytics)}
            />
          </ButtonGroups>
        ),
        title: 'Dashboard',
        type: 'execution',
      }}
      isPrivate
    >
      <DashboardFilterForm
        filters={filters}
        page={pageCriteria}
        showFilters={showFilters}
        sort={sortCriteria}
      />
      <PipelineExecutionDashboardAnalytics
        showAnalytics={showAnalytics}
        variables={{ searchBody: JSON.stringify(analyticsSearchBody) }}
      />
      <Query
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        pollInterval={5000}
        query={allExecutionQuery}
        variables={{ searchBody: JSON.stringify(query) }}
      >
        {({ data, loading }) => {
          const executions = get(data, 'allExecution', []);

          return (
            <>
              <Card m={0} p={5}>
                <Table
                  header={['Pipeline', 'Execution', 'Date', 'Steps']}
                  isLoading={loading}
                  onSortUpdate={(sortBy, orderBy) => {
                    return navigate(
                      setDashboardURL({
                        filters,
                        filterValues: filters.values,
                        page: pageCriteria,
                        pathname: location.pathname,
                        sort: {
                          sortBy,
                          sortDirection: orderBy,
                        },
                      }),
                      { replace: true }
                    );
                  }}
                  orderBy={sortCriteria.sortDirection}
                  rows={executions.map((d) => {
                    return [
                      <Box
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                      >
                        <PipelineExecutionStatusDot
                          status={d.state}
                          sx={{ mr: 2 }}
                        />
                        {d.pipeline ? (
                          <LinkWithVersion
                            link={`/workflows/${d.pipeline.id}`}
                            name={`${d.pipeline.displayName}`}
                            version={parseUuid(d.pipeline.id).version}
                          />
                        ) : (
                          'Unknown Pipeline'
                        )}
                      </Box>,
                      <>
                        {d.pipeline ? (
                          <LinkWithVersion
                            link={`/workflows/${d.pipeline.id}/execution/${d.executionNumber}`}
                            name={`${d.executionNumber}`}
                          />
                        ) : (
                          `${d.executionNumber}`
                        )}
                      </>,
                      formatDateTimeConcise(d.startDate),
                      <PipelineExecutionResultPills results={d.steps} />,
                    ];
                  })}
                  sortBy={sortCriteria.sortBy}
                />
              </Card>
              <TagTooltipBox />
              <DashboardPaginator
                filters={{
                  page: pageCriteria.offset,
                }}
                goToNextPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset + pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                goToPreviousPage={() =>
                  navigate(
                    setDashboardURL({
                      filters,
                      filterValues: filters.values,
                      page: {
                        offset: pageCriteria.offset - pageCriteria.pageSize,
                        pageSize: pageCriteria.pageSize,
                      },
                      pathname: location.pathname,
                      sort: sortCriteria,
                    }),
                    { replace: true }
                  )
                }
                isClientSidePagination
                pageTotal={executions.length}
              />
            </>
          );
        }}
      </Query>
    </Route>
  );
};

ExecutionDashboardPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  navigate: PropTypes.func.isRequired,
};

export default ExecutionDashboardPage;
