import Box from '@shoreag/base/Box';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import get from 'lodash/get';
import { TooltipTrigger } from '@shoreag/base/Tooltip';
import { rgba } from 'polished';
import { ThemeContext } from 'styled-components';
import getExecutionResultPillDetails from '../../utilities/get-execution-result-pill-details';

const PipelineExecutionResultPills = ({ pillSx, results, steps }) => {
  const theme = useContext(ThemeContext);

  const getBackgroundColor = (bgColor) =>
    bgColor === 'gray1' || bgColor === 'grays.3'
      ? bgColor
      : rgba(theme.colors[bgColor], 0.3);

  return (
    <Box display="flex">
      {results.map((result) => {
        const { bgColor, status, stepName } = getExecutionResultPillDetails(
          result
        );

        const defaultStyled = {
          color:
            bgColor === 'gray1' || bgColor === 'grays.3' ? 'grays.4' : bgColor,
          fontWeight: 700,
          textAlign: 'center',
        };

        return (
          <TooltipTrigger
            key={result.stepName}
            data-tip={status}
            data-tip-disable={!status}
            id="tooltip"
          >
            <Box
              sx={{
                bg: getBackgroundColor(bgColor),
                borderRadius: '6px',
                fontSize: 2,
                mr: 3,
                px: 4,
                py: 2,
                ...pillSx,
              }}
            >
              <Box sx={{ mb: 1, ...defaultStyled }}>
                {get(
                  result?.outputs ? JSON.parse(result.outputs) : {},
                  'success',
                  0
                )}
              </Box>
              <Box sx={{ ...defaultStyled }}>
                {get(
                  steps.find((step) => step.name === stepName),
                  'displayName',
                  stepName
                )}
              </Box>
            </Box>
          </TooltipTrigger>
        );
      })}
    </Box>
  );
};

PipelineExecutionResultPills.propTypes = {
  pillSx: PropTypes.shape({}),
  results: PropTypes.arrayOf(
    PropTypes.shape({
      inputs: PropTypes.string,
      outputs: PropTypes.string,
    })
  ).isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
};

PipelineExecutionResultPills.defaultProps = {
  pillSx: {},
  steps: [],
};

export default PipelineExecutionResultPills;
