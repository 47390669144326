import React from 'react';
import Box from '@shoreag/base/Box';
import { IS_BROWSER } from '../../utilities/constants';

const NotFoundPage = () =>
  IS_BROWSER && (
    <Box as="h1" fontSize={6} mb={8} mt={7} textAlign="center">
      404:{' '}
      <Box as="span" fontWeight="normal">
        Page not found.{' '}
        <span aria-label="Shrug emoji." role="img">
          🤷
        </span>
      </Box>
    </Box>
  );

export default NotFoundPage;
