import React from 'react';
import { Router } from '@reach/router';
import NotFoundPage from './404';
import PipelineExecutionDashboardPage from '../../components/PipelineExecutionDashboardPage';

const ExecutionsRouter = (props) => (
  <Router>
    <PipelineExecutionDashboardPage {...props} path="/executions" />
    <NotFoundPage default />
  </Router>
);

export default ExecutionsRouter;
