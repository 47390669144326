import { isEmpty } from 'lodash';
import Box from '@shoreag/base/Box';
import Button from '@shoreag/base/Button';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const ToolbarButton = ({
  active,
  activeSx,
  icon,
  label,
  link,
  loading,
  onClick,
  reverse,
  sx,
  ...rest
}) => {
  const defaultStyled = { bg: 'accent', color: 'white' };
  const activeStyled = !isEmpty(activeSx)
    ? activeSx
    : { bg: 'accentDark', color: 'white' };
  return (
    <Button
      link={link}
      onClick={onClick}
      submitting={loading}
      sx={{
        ...(reverse
          ? {
              '> div': {
                flexDirection: 'row-reverse',
              },
            }
          : {}),
        ...defaultStyled,
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 5,
        ...(active ? activeStyled : {}),
        ...sx,
      }}
      {...rest}
    >
      {icon && (
        <Icon
          svg={icon}
          sx={{
            fill: 'white',
            height: '1rem',
            mx: 1,
            width: '1rem',
          }}
        />
      )}
      {label && (
        <Box
          sx={{
            mx: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {label}
        </Box>
      )}
    </Button>
  );
};

ToolbarButton.defaultProps = {
  active: false,
  activeSx: {},
  icon: '',
  label: '',
  link: '',
  loading: false,
  onClick: () => {},
  reverse: false,
  sx: {},
};

ToolbarButton.propTypes = {
  active: PropTypes.bool,
  activeSx: PropTypes.shape({}),
  icon: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  reverse: PropTypes.bool,
  sx: PropTypes.shape({}),
};

export default ToolbarButton;
