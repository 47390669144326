import Paginator from '@shoreag/base/Paginator';
import PropTypes from 'prop-types';
import React from 'react';
import { Location, navigate } from '@reach/router';
import Icon from '../Icon';
import generateDashboardOnNextClickSearchLink from './utilities/generate-dashboard-on-next-click-search-link';
import generateDashboardOnPreviousClickSearchLink from './utilities/generate-dashboard-on-previous-click-search-link';
import { PAGINATION_PARAMS } from '../../utilities/constants';

const PAGE_SIZE = 10;

const DashboardPaginator = ({
  filters,
  goToPage,
  goToNextPage,
  goToPreviousPage,
  isClientSidePagination,
  pageTotal,
  ...rest
}) => (
  <Location>
    {({ location: { pathname } }) => (
      <Paginator
        leftButtonContent={
          <Icon height="0.75rem" svg="left-chevron" width="0.5rem" />
        }
        offset={filters[PAGINATION_PARAMS.OFFSET]}
        onNextClick={() =>
          isClientSidePagination
            ? goToNextPage()
            : navigate(
                generateDashboardOnNextClickSearchLink({
                  filters,
                  pageSize: PAGE_SIZE,
                  pathname,
                })
              )
        }
        onPageClick={goToPage}
        onPreviousClick={() =>
          isClientSidePagination
            ? goToPreviousPage()
            : navigate(
                generateDashboardOnPreviousClickSearchLink({
                  filters,
                  pageSize: PAGE_SIZE,
                  pathname,
                })
              )
        }
        pageTotal={pageTotal}
        rightButtonContent={
          <Icon height="0.75rem" svg="right-chevron" width="0.5rem" />
        }
        {...rest}
      />
    )}
  </Location>
);
DashboardPaginator.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  goToNextPage: PropTypes.func,
  goToPage: PropTypes.func,
  goToPreviousPage: PropTypes.func,
  isClientSidePagination: PropTypes.bool,
  pageTotal: PropTypes.number.isRequired,
};

DashboardPaginator.defaultProps = {
  goToNextPage: null,
  goToPage: null,
  goToPreviousPage: null,
  isClientSidePagination: false,
};

export default DashboardPaginator;
