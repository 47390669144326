import { capitalCase } from 'change-case';
import { PIPELINE_STEP_STATES } from './constants';

const getExecutionResultPillDetails = (result) => {
  let bgColor = 'gray1';
  let textColor = 'white';
  let status = 'Pending';

  switch (result.state) {
    case PIPELINE_STEP_STATES.RUNNING: {
      bgColor = 'warning';
      status = 'In Progress';
      break;
    }

    case PIPELINE_STEP_STATES.ARCHIVED: {
      bgColor = 'grays.3';
      status = 'Archived';
      break;
    }

    case PIPELINE_STEP_STATES.FAILED: {
      bgColor = 'error';
      status = 'Failed';
      break;
    }

    case PIPELINE_STEP_STATES.UPSTREAM_FAILED: {
      bgColor = 'grays.3';
      status = 'Upstream Failed';
      break;
    }

    case PIPELINE_STEP_STATES.SUCCESS: {
      bgColor = 'success';
      status = 'Succeeded';
      break;
    }

    default: {
      textColor = 'textSubtle';
    }
  }

  return { bgColor, status, stepName: capitalCase(result.stepName), textColor };
};

export default getExecutionResultPillDetails;
